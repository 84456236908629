<template>
  <div class="avatar-image" :style="style" :class="{ small }">
    <i v-if="!url" class="icon-user"></i>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      defaul: false
    }
  },
  computed: {
    style () {
      const style = {}
      if (this.url) {
        style['background-image'] = `url(${this.url})`
      }
      return style
    }
  },
  methods: {
    openFilePicker () {
      this.$refs.fileInputElement.click()
    },
    pickFile () {
      const selectedFile = this.$refs.fileInputElement.files[0]
      if (
        selectedFile &&
        ['image/png', 'image/jpeg'].includes(selectedFile.type)
      ) {
        this.$emit('pick', selectedFile)
      }
      this.$refs.fileInputElement.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-image {
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 8px;
  background-color: #818181;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  overflow: hidden;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 40px;

  &.small {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
    border-radius: 4px;
    font-size: 24px;
  }
}
</style>
