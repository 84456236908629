<template>
  <button
    v-if="active"
    class="btn btn-dark big-list-button"
    @click.stop="removeFromList"
    key="icon-confirm"
  >
    <i class="icon-confirm pop-up-animation mr-1"></i>
    Minha lista
  </button>
  <button
    v-else
    class="btn btn-dark big-list-button"
    @click.stop="addToList"
    key="icon-add-to-list"
  >
    <i class="icon-add-to-list pop-up-animation mr-1"></i>
    Minha lista
  </button>
</template>

<script>
import ListButton from './ListButton'
export default {
  extends: ListButton
}
</script>

<style lang="scss" scoped>
.big-list-button {
  border: none;
  color: white;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  i {
    font-size: 20px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(84, 89, 101, 0.8);
  }

  @media screen and (max-width: 600px) {
    padding: 10px;
  }
}
</style>
