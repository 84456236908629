<template>
  <button
    class="carousel-media"
    :class="{ hover }"
    :style="buttonStyle"
    @click.stop="click"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div v-if="continueWatching" class="progress-bar-wrapper d-flex flex-column justify-content-end">
      <b-progress class="mb-1" height="4px" :value="item.continue_watch.duration" :max="item.video.duration"></b-progress>
    </div>

    <div class="content d-flex flex-column justify-content-end text-left">
      <list-button :content="item" />
      <watch-button
        :content="item"
        play-icon="icon-play-circle mr-1"
        btn-class="btn-link text-light px-0 d-flex align-items-center"
        continueBtnClass="btn-link text-light px-0"
      />
      <h6 class="mb-1">
        <span class="mr-2">{{ tags }}</span>
        {{ duration }}
      </h6>
      <h4 class="d-flex justify-content-between align-items-center">
        <span>{{ item.title }}</span>

        <div v-if="item.season" class="d-flex">
          <progress-ring :progress="item.episode / item.season.episodes" :radius="15">
            <span class="progress-number">{{ item.episode }}/{{ item.season.episodes }}</span>
          </progress-ring>
        </div>
      </h4>
    </div>
  </button>
</template>

<script>
import get from 'lodash/get'
import { getDuration } from '@/functions/helpers'
import ProgressRing from './ProgressRing'

export default {
  components: { ProgressRing },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hover: false,
      debounced: null
    }
  },
  computed: {
    continueWatching () {
      return this.item.continue_watch && this.item.continue_watch.duration > 0
    },
    image () {
      return get(get(this.item, 'thumbnails', []).find(item => item.device === 'web'), 'small', '')
    },
    buttonStyle () {
      return {
        'background-image': `url(${this.image})`
      }
    },
    tags () {
      return get(this.item, 'tags', []).map(item => `#${item.name}`).join(' ')
    },
    duration () {
      return getDuration(
        get(this.item, 'video.duration', 0)
      )
    }
  },
  methods: {
    click () {
      this.$router.push({
        name: 'content',
        params: {
          id: this.item.id,
          slug: this.item.slug
        }
      })
    },
    mouseenter () {
      if (window.innerWidth > 991) {
        this.debounced = setTimeout(() => {
          this.hover = true
          this.$emit('mouseenter')
        }, 200)
      }
    },
    mouseleave () {
      clearTimeout(this.debounced)
      this.hover = false
      this.$emit('mouseleave')
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-media {
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  max-height: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  transition: 0.3s transform;
  border: none;
  text-shadow: 0 0 5px black;

  .content {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    opacity: 0;
    transition: 0.3s all;

    .like-button {
      position: absolute;
      top: 4px;
      right: 4px;
    }

    .list-button {
      position: absolute;
      top: 24px;
      right: 4px;
    }

    h6 {
      font-size: 8px;
      line-height: 10px;
    }

    h4 {
      font-weight: 900;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 0;
    }

    h5 {
      font-size: 10px;
      font-weight: 400;

      .icon-play-circle {
        font-size: 18px;
      }
    }
  }

  .progress-bar-wrapper {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    color: white;
    padding: 12px 48px;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: 0.3s all;
  }

  &.hover {
    transform: scale(1.5);
    z-index: 1;

    .progress-bar-wrapper {
      opacity: 0;
      background: transparent;
    }

    .content {
      background: rgba(0, 0, 0, 0.8);
      opacity: 1;
      color: white;
      padding: 12px;
    }
  }

  ::v-deep .watch-button {
    button {
      font-size: 10px;
    }
    .continue-watching {
      i {
        font-size: 18px;
      }
    }
    .play-button {
      i {
        font-size: 14px;
      }
    }
  }

  .progress-number {
    font-size: 8px;
    line-height: 30px;
    font-weight: bold;
  }
}
</style>
