export default {
  setEpisodes (state, episodes) {
    state.episodes = episodes.sort((a, b) => a.episode - b.episode)
  },
  setLaunches (state, launches) {
    state.launches = launches
  },
  setWeekly (state, weekly) {
    state.weekly = weekly
  },
  setRecommendations (state, recommendations) {
    state.recommendations = recommendations
  },
  setContinuations (state, continuations) {
    state.continuations = continuations
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setCategoryContents (state, categoryContents) {
    state.categoryContents = categoryContents
  },
  setSearchResult (state, searchResult) {
    state.searchResult = searchResult
  },
  setContent (state, content) {
    state.content = content
  }
}
