export function toHumanDate (date) {
  return date
    .split('-')
    .reverse()
    .join('/')
}

export function dayDiff (dateString) {
  var today = new Date()
  var date = new Date(dateString)

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime()

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24))
}

export const emojis = [
  '😁',
  '😂',
  '😃',
  '😄',
  '😅',
  '😆',
  '😉',
  '😊',
  '😋',
  '😌',
  '😍',
  '😏',
  '😒',
  '😓',
  '😔',
  '😖',
  '😘',
  '😚',
  '😜',
  '😝',
  '😞',
  '😠',
  '😢',
  '😤',
  '😥',
  '😨',
  '😩',
  '😪',
  '😫',
  '😭',
  '😰',
  '😱',
  '😲',
  '😳',
  '😵',
  '😷',
  '😸',
  '😹',
  '😺',
  '😻',
  '😼',
  '😽',
  '😾',
  '😿',
  '🙀',
  '🙅',
  '🙆',
  '🙇',
  '🙈',
  '🙉',
  '🙊',
  '🙋',
  '🙌',
  '🙍',
  '🙎',
  '🙏'
]

export function getRandomVideoThumb (index) {
  const videos = [
    '2nalrpu9pLM',
    'qu6rXsXNpoY',
    'cLIZI8IVbDk',
    'DJ2NXpnbBRY',
    'KlFpA_bhZB0',
    'Qq26kOtSbog'
  ]

  let video = ''
  if (index) {
    video = videos[index % videos.length]
  } else {
    video = videos[Math.floor(Math.random() * videos.length)]
  }

  return `https://i.ytimg.com/vi/${video}/maxresdefault.jpg`
}

export function getDuration (secs) {
  let result = ''
  const hours = Math.floor(secs / (60 * 60))
  const mins = Math.floor(secs / 60) % 60
  if (hours > 0) result += `${hours}h`
  if (mins > 0) result += `${mins}min`
  return result
}

export function getMoment (time) {
  let result = ''
  const hours = Math.floor(time / (60 * 60))
  const mins = `${Math.floor(time / 60) % 60}`.padStart(2, '0')
  const secs = `${time % 60}`.padStart(2, '0')
  if (hours > 0) result += `${hours}:`
  result += `${mins}:${secs}`
  return result
}

export function sortSeason (items) {
  return [...items].sort((a, b) => a.season.number === b.season.number
    ? a.episode - b.episode
    : a.season.number - b.season.number
  )
}
