import Vue from 'vue'
import Router from 'vue-router'
// import VueGtm from "vue-gtm";

import Home from './views/Home.vue'
import DefaultTemplate from './templates/Default.vue'

Vue.use(Router)

const vueRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/series',
      name: 'series',
      component: () => import('./views/Series.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/categorias',
      name: 'categories',
      component: () => import('./views/Categories.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/lancamentos',
      name: 'launches',
      component: () => import('./views/Launches.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/recomendados',
      name: 'recommendations',
      component: () => import('./views/Recommendations.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/lista',
      name: 'list',
      component: () => import('./views/List.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/:id/:slug',
      name: 'content',
      component: () => import('./views/Content.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/curso/:id/:slug',
      name: 'season',
      component: () => import('./views/Season.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/assistir/:id/:slug',
      name: 'watch',
      component: () => import('./views/Watch.vue'),
      meta: {
        template: () => import('./templates/Blank.vue'),
        protected: true
      }
    },
    {
      path: '/minha-conta',
      name: 'account',
      component: () => import('./views/Account.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: '/busca',
      name: 'search',
      component: () => import('./views/Search.vue'),
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    // auth
    {
      path: '/cadastro',
      name: 'register',
      component: () => import('./views/Register.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },
    {
      path: '/pagamento',
      name: 'payment',
      component: () => import('./views/Payment.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },
    {
      path: '/verifica-pagamento',
      name: 'check-payment',
      component: () => import('./views/CheckPayment.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },
    {
      path: '/planos',
      name: 'plans',
      component: () => import('./views/Plans.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },
    {
      path: '/password',
      name: 'password',
      component: () => import('./views/ResetPassword.vue'),
      meta: {
        template: () => import('./templates/Auth.vue'),
        protected: false
      }
    },

    // extra
    {
      path: '/politica-de-privacidade',
      name: 'privacy',
      component: () => import('./views/Privacy.vue'),
      meta: {
        template: () => import('./templates/Blank.vue'),
        protected: false
      }
    },
    {
      path: '/termo-de-consentimento',
      name: 'termscons',
      component: () => import('./views/TermCons.vue'),
      meta: {
        template: () => import('./templates/Blank.vue'),
        protected: false
      }
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import('./views/NotFound.vue'),
      meta: {
        template: () => import('./templates/Blank.vue'),
        protected: false
      }
    }
  ]
})

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter
