<template>
  <div class="season-button d-none d-lg-block">
    <button v-if="content && content.season" class="d-flex" @click="click">
      <progress-ring :progress="content.episode / content.season.episodes">
        <strong>{{ content.episode }}</strong>
      </progress-ring>
      <div class="ml-2 d-flex flex-column align-items-start">
        <small>{{ content.episode }}/{{ content.season.episodes }} Assista a temporada completa:</small>
        <span>{{ content.season.name }}</span>
      </div>
    </button>
  </div>
</template>

<script>
import ProgressRing from './ProgressRing'
export default {
  components: { ProgressRing },
  props: {
    content: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    click () {
      this.$router.push({
        name: 'content',
        params: {
          id: this.content.season.first_episode_id,
          slug: this.content.season.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.season-button {
  position: absolute;
  right: 0;
  top: 550px;

  button {
    background: #212631;
    color: white;
    border: none;
    padding: 12px 16px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;

    small {
      opacity: 0.6;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
</style>
