export default {
  get (context, { id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/comments/content?content_id=${id}`)
        .then(function ({ data }) {
          context.commit('setComments', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  create (context, { contentId, text, interval }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/comments/', { content_id: contentId, text, interval })
        .then(resolve)
        .catch(reject)
    })
  },

  update (context, { id, text }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put('/comments/', { id, text })
        .then(resolve)
        .catch(reject)
    })
  },

  remove (context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/comments/${id}`)
        .then(resolve)
        .catch(reject)
    })
  }
}
