import { removeToken } from '../setup/api'

export default {
  postLogin (context, checkSubscription = true) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)

      const actions = [context.dispatch('user/get')]
      if (checkSubscription) {
        actions.push(context.dispatch('user/checkSubscription'))
      }

      Promise.all(actions)
        .then(() => {
          context.commit('user/setLogged', true)
          resolve()
        })
        .catch(reject)
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },

  logout (context) {
    return new Promise(resolve => {
      removeToken()
      context.commit('user/reset')
      resolve()
    })
  }
}
