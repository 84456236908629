export default {
  episodes: [],
  launches: [],
  weekly: [],
  recommendations: [],
  continuations: [],
  categories: [],
  categoryContents: [],
  searchResult: [],
  content: {}
}
