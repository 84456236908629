var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-home"},[(!_vm.headerContent)?_c('div',{staticClass:"header-spacing"}):_vm._e(),(_vm.headerSeason)?_c('action-dispatcher',{attrs:{"action":"seasons/get","parameters":_vm.headerSeason.id,"loading-class":"p-5 m-5"}},[(_vm.headerContent)?_c('page-header',{attrs:{"image":_vm.headerImage,"lighter":""}},[(_vm.headerContent.season)?_c('h5',[_vm._v(_vm._s(_vm.headerContent.season_tmp.name))]):_vm._e(),_c('h6',[(_vm.headerContent.season)?_c('span',{staticClass:"mr-2"},[_vm._v("Temporada "+_vm._s(_vm.headerContent.season.number))]):_vm._e(),(_vm.headerContent.episode)?_c('span',[_vm._v("Episódio "+_vm._s(_vm.headerContent.episode))]):_vm._e()]),_c('router-link',{staticClass:"text-light text-decoration-none",attrs:{"to":{
        name: 'content',
        params: {
          id: _vm.headerContent.id,
          slug: _vm.headerContent.slug
        }
      }}},[_c('h1',[_vm._v(_vm._s(_vm.headerContent.title))])]),_c('p',[_c('span',[_vm._v("Categoria: "),_c('strong',[_vm._v(_vm._s(_vm.headerCategory))])])]),_c('div',{staticClass:"header-buttons d-flex mb-3"},[_c('watch-button',{staticClass:"mr-2",attrs:{"content":_vm.headerContent}}),_c('div',{staticClass:"mr-2"},[_c('big-list-button',{attrs:{"content":_vm.headerContent}})],1)],1)],1):_vm._e()],1):_vm._e(),_c('season-button',{attrs:{"content":_vm.headerContent}}),_c('div',{staticClass:"container-fluid pb-5"},[_c('div',{staticClass:"container"},[(_vm.highlights && _vm.highlights.length)?[_c('h4',{staticClass:"mb-0 mb-lg-1 mt-4 mt-lg-5"},[_vm._v(" Destaques ")]),_c('series',{attrs:{"seasons":_vm.highlights,"useLandscape":false}})]:_vm._e(),_c('action-dispatcher',{attrs:{"action":"contents/getCategories"}},[_vm._l((_vm.categories),function(cat){return [(cat && cat.seasons.length)?_c('h4',{staticClass:"mb-0 mb-lg-1 mt-4 mt-lg-5"},[_vm._v(" "+_vm._s(cat.name)+" ")]):_vm._e(),(cat && cat.seasons.length)?_c('series',{attrs:{"seasons":[...cat.seasons],"categorySize":true}}):_vm._e()]})],2),_c('h4',{staticClass:"mb-0 mb-lg-1 mt-4 mt-lg-5"},[_vm._v(" Meus cursos ")]),_c('series',{attrs:{"seasons":_vm.orderedSeasons,"useLandscape":false}}),_c('action-dispatcher',{attrs:{"action":"homepage/getAlerts"}},[(_vm.alert)?_c('h4',{staticClass:"mb-0 mb-lg-1 mt-4 mt-lg-5"},[_vm._v(" Alertas Importantes ")]):_vm._e(),(_vm.alert)?_c('div',{staticClass:"row align-items-center pt-5"},[(_vm.alert.picture)?_c('div',{staticClass:"col-lg py-3"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.alert.picture}})]):_vm._e(),_c('div',{staticClass:"col-lg py-3"},[_c('h3',[_vm._v(_vm._s(_vm.alert.title))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.alert.description)}})])]):_vm._e()])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }