export default {
  name: '',
  description: '',
  price: '',
  discount: '',
  coupon: '',
  items: [],
  trialPeriodDays: 7,
  paymentMethods: []
}
