export default {
  getHighlights (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/homepage')
        .then(function (response) {
          context.commit('setHighlights', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },
  getMainHighlight (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/homepage/main')
        .then(function (response) {
          context.commit('setMainHighlight', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  getCategories (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/homepage/categories')
        .then(function (response) {
          context.commit('setCategories', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },
  getCategory (context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/homepage/categories/${id}`)
        .then(function (response) {
          context.commit('setCategory', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  getAlerts (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/homepage/alerts')
        .then(function (response) {
          context.commit('setAlerts', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  }
}
