<template>
  <div class="form-group" :class="{ 'has-icon': !!icon, 'has-value': !!value }">
    <label :for="id" v-if="label">{{ label }}</label>
    <i v-if="icon" :class="'icon ' + icon"></i>
    <float-label :label="placeholder">
      <component
        :is="component"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : autocomplete"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
        :mask="mask"
        :required="required"
        @input="input"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @country-changed="obj => $emit('country-changed', obj)"
        @validate="obj => $emit('validate', obj)"
      />
      <div v-if="showError" class="invalid-feedback">{{ errors[0] }}</div>
    </float-label>
    <button v-if="withClearButton && value.length" class="clear" @click="clear">
      <i class="icon-x"></i>
    </button>
  </div>
</template>

<script>
import FloatLabel from 'vue-float-label/components/FloatLabel'

export default {
  components: {
    FloatLabel
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    },
    errors: {
      type: Array,
      default: () => []
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withClearButton: {
      type: Boolean,
      default: false
    },
    mask: {
      type: String,
      default: () => ''
    },
    isPhone: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showError: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    classObject () {
      const result = { 'is-invalid': this.showError, disabled: this.disabled }
      result[this.customClass] = true
      return result
    },
    component () {
      if (this.isPhone) {
        return 'vue-tel-input'
      } else if (this.mask.length) {
        return 'the-mask'
      } else {
        return 'input'
      }
    }
  },
  watch: {
    errors (newVal) {
      if (newVal && newVal.length) {
        this.showError = true
      }
    }
  },
  methods: {
    input (event) {
      this.showError = false
      if (event.target) {
        this.$emit('input', event.target.value)
      } else {
        this.$emit('input', event)
      }
    },
    clear () {
      this.$emit('input', '')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 24px;

  .form-control {
    border: none;
    background: #212631;
    border-radius: 8px;
    height: 48px;
    color: white;

    &:focus {
      box-shadow: none;
    }
  }

  &.has-value {
    .form-control:not(.disabled) {
      background: white;
      color: #212631;
    }
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 38px;
      left: 4px;
    }

    .form-control {
      padding-left: 32px;
    }
  }

  .clear {
    position: absolute;
    height: 38px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }

  ::v-deep .vfl-label-on-input {
    color: white !important;
    opacity: 0.5;
    top: -1.5em;
  }

  ::v-deep .vue-tel-input {
    .vti__input {
      background: transparent;
    }
  }

  ::v-deep .vti__dropdown {
    &:hover,
    &.open {
      background: transparent;
    }
  }

  ::v-deep .vti__dropdown-list {
    background: #212631;
    border-color: #212631;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

    .vti__dropdown-item.highlighted {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
</style>
