export default {
  get (context, planref) {
    const url = planref ? `/plans/${planref}` : '/plans/'

    return new Promise((resolve, reject) => {
      window.axios
        .get(url)
        .then(function ({ data }) {
          if (data.coupon) {
            context.commit('setName', data.name)
            context.commit('setDescription', data.description)
            context.commit('setPrice', data.price_with_discount)
            context.commit('setDiscount', data.discount_value)
            context.commit('setCoupon', data.coupon)
            context.commit('setItems', data.json.items)
            context.commit('setTrialPeriodDays', data.json.trial_period_days)
            context.commit('setPaymentMethods', data.json.payment_methods)
          }

          resolve(data)
        })
        .catch(reject)
    })
  }
}
