<template>
  <div class="series">

    <carousel
      :per-page-custom="[[768, 3], [992, 4], [1400, 5]]"
      :navigation-enabled="navigationEnabled"
      :pagination-enabled="false"
      navigation-next-label="<i class='icon-right'></i>"
      navigation-prev-label="<i class='icon-left'></i>"
    >
      <slide
        v-for="(season, i) in seasons"
        :key="i"
        class="px-1"
      >
        <serie-button
          :season="season"
          :categorySize="categorySize"
          :useLandscape="useLandscape"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        />
        <!-- <carousel-media
          :item="item"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        /> -->
      </slide>
    </carousel>

    <!-- <div class="row">
      <div
        v-for="(season, index) in seasons"
        :key="index"
        class="col-lg-3 col-md-6"
      >
        <serie-button :season="season" />
      </div>
    </div> -->
  </div>
</template>

<script>
import SerieButton from '@/components/SerieButton'

export default {
  components: { SerieButton },
  props: {
    seasons: {
      type: Array,
      default: () => []
    },
    categorySize: {
      type: Boolean,
      default: false,
    },
    useLandscape: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      navigationEnabled: true
    }
  },
  methods: {
    mouseenter () {
      this.navigationEnabled = false
    },
    mouseleave () {
      this.navigationEnabled = true
    }
  }
}
</script>
