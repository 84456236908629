<template>
  <form @submit.prevent="submit" class="template-search">
    <button v-if="!showingInput" type="button" class="search" @click="showInput">
      <i class="icon-search"></i>
    </button>
    <input
      v-else
      v-model="text"
      placeholder="Pesquise um título, palavra ou categoria"
      type="text"
      ref="input"
      v-click-outside="hideInput"
    />
  </form>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside
  },
  data () {
    return {
      showingInput: false,
      text: ''
    }
  },
  methods: {
    showInput () {
      setTimeout(async () => {
        this.showingInput = true
        await this.$nextTick()
        this.$refs.input.focus()
      }, 1)
    },
    hideInput () {
      if (this.showingInput) {
        this.showingInput = false
      }
    },
    submit () {
      this.$router.push({ name: 'search', query: { q: this.text } })
      this.text = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.template-search {
  button {
    font-size: 32px;
    color: white;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  input {
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid white;
    border-radius: 20px;
    color: white;
    padding: 4px 16px;
    animation-name: grow-animation;
    animation-duration: 0.1s;
    font-size: 14px;
    width: 280px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

@keyframes grow-animation {
  0%   {width: 20px;}
  100% {width: 280px;}
}
</style>
