export default {
  setUser (state, user) {
    state.user = user
  },
  updateUser (state, data) {
    for (const i in data) {
      state.user[i] = data[i]
    }
  },
  setLogged (state, logged) {
    state.logged = logged
  },
  setMyList (state, myList) {
    state.myList = myList
  },
  addToMyList (state, content) {
    state.myList = state.myList.concat({
      id: content.id,
      content: {
        ...content,
        favorite: true
      }
    })
  },
  removeFromMyList (state, id) {
    state.myList = state.myList.filter(item => item.content.id !== id)
  },

  reset (state) {
    state.user = null
    state.logged = false
  },

  setNotifications (state, notifications) {
    state.notifications = notifications
  },

  setActive (state, active) {
    state.active = active
  }
}
