import Vue from 'vue'
import VueCarousel from 'vue-carousel'
import { OrbitSpinner } from 'epic-spinners'

import FormGroup from './FormGroup.vue'
import Loading from './Loading.vue'
import Snackbar from './Snackbar.vue'
import LikeButton from './LikeButton.vue'
import ListButton from './ListButton.vue'
import BigListButton from './BigListButton.vue'
import Confirmation from './Confirmation.vue'
import ActionDispatcher from './ActionDispatcher.vue'
import WatchButton from './WatchButton.vue'

Vue.use(VueCarousel)

Vue.component('form-group', FormGroup)
Vue.component('loading', Loading)
Vue.component('snackbar', Snackbar)
Vue.component('loading-spinner', OrbitSpinner)
Vue.component('like-button', LikeButton)
Vue.component('list-button', ListButton)
Vue.component('big-list-button', BigListButton)
Vue.component('confirmation', Confirmation)
Vue.component('action-dispatcher', ActionDispatcher)
Vue.component('watch-button', WatchButton)
