<template>
  <div id="app">
    <component :is="template">
      <transition
        name="fade"
        mode="out-in"
      >
        <div v-if="loading" class="page-loading">
          <loading-spinner />
        </div>

        <router-view :key="$route.fullPath" v-else />
      </transition>
    </component>

    <snackbar />
  </div>
</template>

<script>
import { getToken } from './setup/api'

export default {
  data () {
    return {
      loading: false
    }
  },
  computed: {
    template () {
      return this.$route.meta.template
    },
    isLogged () {
      return this.$store.state.user.logged
    },
    isActive () {
      return this.$store.state.user.active
    },
    showRouterView () {
      return !this.$route.meta.protected || this.isLogged
    }
  },
  beforeMount () {
    const urlParams = new URLSearchParams(window.location.search)
    const planref = urlParams.get('planref')
    if (planref) { window.localStorage.setItem('planref', planref) }

    if (!getToken() && this.$route.meta.protected) {
      this.$router.replace({ name: 'login' })
    } else if (getToken()) {
      this.loading = true
      this.$store
        .dispatch('postLogin')
        .catch(() => {
          this.$store.dispatch('logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        })
        .finally(() => {
          this.loading = false
        })

      if (['/login', '/register'].includes(document.location.pathname)) {
        this.$router.replace({ name: 'home' })
      }
    }
  },
  watch: {
    $route: function () {
      if (!this.isLogged && this.$route.meta.protected) {
        this.$router.replace({ name: 'login' })
      }
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  .page-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
  }
}
</style>
