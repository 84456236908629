<template>
  <div class="watch-button">
    <button
      v-if="continueWatching"
      class="btn continue-watching py-1 d-flex align-items-center text-decoration-none"
      :class="continueBtnClass"
      @click="watch"
    >
      <i class="icon-play-circle mr-2"></i>
      <div class="d-flex flex-column">
        <span class="mb-1 mobile-small">Continuar assistindo</span>
        <b-progress class="mb-1" height="4px" :value="content.continue_watch.duration" :max="content.video.duration"></b-progress>
      </div>
    </button>

    <button
      v-else
      class="btn play-button"
      :class="btnClass"
      @click="watch"
    >
      <template v-if="watched">
        <i class="icon-check"></i>
        Assistir novamente
      </template>
      <template v-else>
        <i :class="playIcon"></i>
        Assistir
      </template>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    continueBtnClass: {
      type: String,
      default: 'btn-link text-light'
    },
    btnClass: {
      type: String,
      default: 'btn-light'
    },
    playIcon: {
      type: String,
      default: 'icon-play'
    },
    content: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    watched () {
      return this.content.continue_watch.finished
    },
    continueWatching () {
      return this.content.continue_watch.duration > 0
    }
  },
  methods: {
    watch () {
      this.$router.push({
        name: 'watch',
        params: {
          id: this.content.id,
          slug: this.content.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.watch-button {
  .btn {
    white-space: nowrap;
    border-radius: 8px;
  }

  .continue-watching i {
    font-size: 32px;
  }

  .mobile-small {
    @media screen and (max-width: 575px) {
      font-size: 12px;
    }
  }
}
</style>
