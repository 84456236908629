export default {
  get (context, { id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/get/${id}`)
        .then(function (response) {
          context.commit('setContent', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  getSeason (context, { id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/contents/seasons', { season_id: id })
        .then(function (response) {
          context.commit('setEpisodes', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },

  getLaunches (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/contents/launches')
        .then(function ({ data }) {
          context.commit('setLaunches', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getWeekly (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/contents/weekly')
        .then(function ({ data }) {
          context.commit('setWeekly', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getRecommendations (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/contents/recommendations')
        .then(function ({ data }) {
          context.commit('setRecommendations', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  setContinuations (context, { id, finished, duration }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/contents/continue', { content_id: id, finished, duration })
        .then(resolve)
        .catch(reject)
    })
  },

  getContinuations (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/contents/continue')
        .then(function ({ data }) {
          context.commit('setContinuations', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getCategories (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/contents/categories/details/')
        .then(function ({ data }) {
          context.commit('setCategories', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getCategoryContents (context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/contents/categories/${id}`)
        .then(function ({ data }) {
          context.commit('setCategoryContents', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  search (context, query) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/contents/search', { query })
        .then(function ({ data }) {
          context.commit('setSearchResult', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  like (context, { id, score }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/contents/like', { content_id: id, score })
        .then(resolve)
        .catch(reject)
    })
  }
}
