// const baseURL = 'https://api.ocorpoexplica.com.br/v2/'
const baseURL = process.env.VUE_APP_BACKEND_HOST+'/v2/'

// const _post = (url, params, config = {}) =>
//   window.axios.post(url, params, { baseURL, ...config })
// const _put = (url, params) => window.axios.put(url, params, { baseURL })
// const _delete = url => window.axios.delete(url, { baseURL })
const _get = url => window.axios.get(url, { baseURL })

export default {
  getAll (context) {
    return new Promise((resolve, reject) => {
      _get('/seasons')
        .then(function (response) {
          context.commit('setSeasons', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  },
  get (context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/seasons/${id}`)
        .then(function (response) {
          context.commit('setSeason', response.data)
          resolve(response.data)
        })
        .catch(reject)
    })
  }
}
