<template>
  <div class="template-default">
    <confirmation
      v-model="logoutDialog"
      @confirm="$user.logout"
      title="Tem certeza que deseja sair?"
      text="Ao confirmar, você será desconectado(a) da nossa plataforma e poderá acessar novamente quando desejar."
      confirm-button-text="Sim, sair"
      confirm-button-class="btn-secondary"
    />

    <nav class="navbar navbar-expand-lg text-white">
      <router-link :to="{ name: 'home' }" class="navbar-brand mr-5">
        <img src="@/assets/logo.png" alt="OCE Play">
      </router-link>

      <div class="flex-fill d-lg-none"></div>
      <notifications class="d-lg-none" />

      <button class="navbar-toggler" type="button" @click="$bvModal.show('mobile-menu')">
        <i class="icon-bars text-white"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <main-menu @logout="logoutDialog = true" />

        <search />
        <notifications />
        <account-menu @logout="logoutDialog = true" />
      </div>
    </nav>

    <b-modal
      id="mobile-menu"
      hide-footer
    >
      <template v-slot:modal-title>
        <router-link :to="{ name: 'home' }" @click.native="$bvModal.hide('mobile-menu')">
          <img src="@/assets/logo.png" class="mobile-menu-logo" alt="OCE Play">
        </router-link>
      </template>

      <main-menu
        @close="$bvModal.hide('mobile-menu')"
        @logout="logoutDialog = true"
      />
    </b-modal>

    <action-dispatcher action="seasons/getAll" loading-class="p-5 m-5">
      <slot />
    </action-dispatcher>

    <footer>
      <div class="container py-5">
        <img src="@/assets/logo.png" class="logo mb-3" />

        <div class="row mb-5">
          <div class="col-lg">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" href="https://ocorpoexplica.com.br/termos-de-uso/" target="_blank">Termos de uso</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="#">Ajuda</a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="mailto:suporte@ocorpoexplica.com.br">Entre em contato</a>
              </li>
            </ul>
          </div>
          <div class="col-lg">
            <!-- <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'list' }">Minha lista</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'categories' }">Categorias</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'launches' }">Lançamentos</router-link>
              </li>
            </ul> -->
          </div>
          <div class="col-lg">
            <ul class="nav flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'privacy' }">Política de privacidade</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'termscons' }">Termo de Consentimento e Uso de Dados</router-link>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" href="javascript:void" @click="shareDialog = true">Indique para um amigo</a>
              </li> -->
            </ul>
          </div>
          <div class="col-lg-4 text-lg-right mt-5 mt-lg-0">
            <p>Acompanhe nas redes sociais:</p>

            <ul class="list-inline">
              <li class="list-inline-item">
                <a href="https://pt-br.facebook.com/ocorpoexplica/" target="_blank">
                  <i class="icon-facebook-square"></i>
                </a>
              </li>
              <li class="list-inline-item mx-1" />
              <li class="list-inline-item">
                <a href="https://www.instagram.com/ocorpoexplica/" target="_blank">
                  <i class="icon-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item mx-1" />
              <li class="list-inline-item">
                <a href="https://www.youtube.com/channel/UCTlqNHmHCDSqaxPDNqFS-fA" target="_blank">
                  <i class="icon-youtube-play"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <small>© OCE Play - {{ year }}</small>
      </div>
    </footer>

    <b-modal
      v-model="shareDialog"
      centered
      hide-footer
      title="Compartilhe:"
    >
      <div class="share-row">
        <share-it
          url="https://play.ocorpoexplica.com.br/"
          :targets="['twitter', 'facebook', 'linkedin']"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import MainMenu from '@/components/template/MainMenu'
import AccountMenu from '@/components/template/AccountMenu'
import Notifications from '@/components/template/Notifications'
import Search from '@/components/template/Search'

export default {
  components: {
    MainMenu,
    AccountMenu,
    Notifications,
    Search
  },
  data () {
    return {
      shareDialog: false,
      logoutDialog: false,
      year: (new Date()).getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.template-default {
  background: #000215;
  color: white;

  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));

    .navbar-brand {
      img {
        height: 48px;
      }
    }

    ::v-deep .nav-link {
      border-bottom: 3px solid transparent;
      transition: 0.2s border-color;
      margin-right: 16px;
      text-shadow: 0 0 5px black;
      font-weight: 700;

      &.router-link-exact-active,
      &:hover {
        border-color: white;
      }
    }

    .logout {
      font-weight: 700;
    }
  }

  footer {
    .logo {
      height: 48px;
    }

    .nav-link {
      color: white;
      padding-left: 0;
      font-weight: bold;
      opacity: 0.7;
      transition: 0.2s opacity;

      &:hover {
        opacity: 1;
      }
    }

    .list-inline {
      a {
        color: white;
        font-size: 24px;
        opacity: 1;
        transition: 0.2s opacity;

        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }
  }
}

.share-row {
  margin: 0 -8px;

  ::v-deep .vue-share-it-button {
    margin: 0;
    padding: 8px;

    label {
      margin-bottom: 0;
    }
  }
}
</style>
