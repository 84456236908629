<template>
  <div class="template-account-menu">
    <b-dropdown variant="link" toggle-class="text-decoration-none" right no-caret>
      <template v-slot:button-content>
        <avatar-image v-if="$user.user" small :url="$user.user.picture" class="d-inline-block mr-2" />
      </template>

      <b-dropdown-item @click="$router.push({ name: 'account' })">
        <div class="d-flex">
          <i class="account-icon icon-user d-flex align-items-center mr-1"></i>
          <span>Perfil e assinatura</span>
        </div>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item @click="$emit('logout')">
        <span class="logout">Sair</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import AvatarImage from '../account/AvatarImage'

export default {
  components: { AvatarImage }
}
</script>

<style lang="scss" scoped>
.template-account-menu {
  .account-icon {
    font-size: 20px;
  }

  .logout {
    color: rgba(255, 255, 255, 0.7)
  }

  ::v-deep .dropdown-toggle::after {
    color: white;
    display: initial;
    vertical-align: middle;
  }

  ::v-deep .dropdown-menu {
    background: #212631;
    border-radius: 8px;
    text-align: center;

    a {
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    hr {
      border-top-color: rgba(255, 255, 255, 0.05);
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #212631;
      top: -8px;
      right: 20px;
    }
  }
}
</style>
