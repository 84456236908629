import { setToken } from '../../setup/api'

export default {
  login (context, { email, password }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/auth/', { email, password })
        .then(function (response) {
          setToken(response.data.token)
          context.commit('setLogged', true)
          resolve()
        })
        .catch(reject)
    })
  },

  facebookLogin (context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/auth/oauth/login', data)
        .then(function (response) {
          setToken(response.data.token)
          context.commit('setLogged', true)
          resolve()
        })
        .catch(reject)
    })
  },

  register (context, { name, document, phone, email, password }) {
    const data = {
      name,
      document,
      phone,
      email,
      password
    }

    return new Promise((resolve, reject) => {
      window.axios
        .post('/accounts/subscribers', data)
        .then(function (response) {
          setToken(response.data.token)
          context.commit('setLogged', true)
          context.dispatch('postLogin', null, { root: true })
          resolve()
        })
        .catch(reject)
    })
  },

  card (context, { number, name, month, year, cvv, coupon }) {
    const data = {
      // holder_document: document,
      number,
      holder_name: name,
      exp_month: month,
      exp_year: year,
      cvv,
      coupon
    }

    return new Promise((resolve, reject) => {
      window.axios
        .post('/accounts/cards', data)
        .then(resolve)
        .catch(reject)
    })
  },

  changeCard (context, { document, number, name, month, year, cvv, coupon }) {
    const data = {
      holder_document: document,
      number,
      holder_name: name,
      exp_month: month,
      exp_year: year,
      cvv,
      coupon
    }

    return new Promise((resolve, reject) => {
      window.axios
        .post('/accounts/cards/change', data)
        .then(resolve)
        .catch(reject)
    })
  },

  hasCard () {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/accounts/has-card')
        .then(resolve)
        .catch(reject)
    })
  },

  forgot (context, { email }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/auth/reset-password', { email })
        .then(resolve)
        .catch(reject)
    })
  },

  resetPassword (context, { password, token }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post('/auth/reset-password-change', { password, token })
        .then(resolve)
        .catch(reject)
    })
  },

  get (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/accounts/details')
        .then(function (response) {
          context.commit('setUser', response.data)
          if (window.FS) {
            window.FS.identify(response.data.id, {
              displayName: response.data.name,
              email: response.data.email
            })
          }
          resolve()
        })
        .catch(reject)
    })
  },

  update (context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .put('/accounts/info', data)
        .then(function () {
          context.commit('updateUser', data)
          resolve()
        })
        .catch(reject)
    })
  },

  updatePassword (context, { newPassword, oldPassword }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put('/auth/change-password', {
          password: newPassword,
          password_old: oldPassword
        })
        .then(resolve)
        .catch(reject)
    })
  },

  getMyList (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/accounts/my-list')
        .then(function ({ data }) {
          context.commit('setMyList', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  addToList (context, content) {
    return new Promise((resolve, reject) => {
      for (var i in context.state.myList) {
        if (context.state.myList[i].id === content.id) return
      }
      window.axios
        .post('/accounts/my-list', { content_id: content.id })
        .then(({ data }) => {
          context.commit('addToMyList', content)
          resolve(data)
        })
        .catch(reject)
    })
  },

  removeFromList (context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/accounts/my-list/${id}`)
        .then(({ data }) => {
          context.commit('removeFromMyList', id)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getNotifications (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/releases')
        .then(function ({ data }) {
          context.commit('setNotifications', data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  checkSubscription (context) {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/accounts/active-subscription')
        .then(function (response) {
          context.commit('setActive', response.data.message)

          if (response.data.message === 'in_process') {
            setTimeout(() => {
              context.dispatch('checkSubscription')
            }, 15 * 1000)
          }

          resolve(response.data.message)
        })
        .catch(reject)
    })
  }
}
