<template>
  <button
    v-if="active"
    class="pop-up-animation list-button"
    @click.stop="removeFromList"
    key="icon-confirm"
  >
    <i class="icon-confirm"></i>
  </button>
  <button
    v-else
    class="pop-up-animation list-button"
    @click.stop="addToList"
    key="icon-add-to-list"
  >
    <i class="icon-add-to-list"></i>
  </button>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      active: this.content.favorite
    }
  },
  methods: {
    addToList () {
      this.active = true
      this.$store.dispatch('user/addToList', this.content)
    },
    removeFromList () {
      this.active = false
      this.$store.dispatch('user/removeFromList', this.content.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-button {
  background: transparent;
  border: none;
  color: white;

  &:focus {
    outline: none;
  }
}
</style>
