<template>
  <div class="template-notifications">
    <b-dropdown variant="link" toggle-class="text-decoration-none" right no-caret>
      <template v-slot:button-content>
        <i class="icon-notification"></i>
      </template>
      <action-dispatcher action="user/getNotifications">
        <b-dropdown-item v-for="(notification, index) in notifications" :key="index" @click="clicked(notification)">
          <div class="d-flex align-items-center w-100">
            <img
              v-if="notification.content.thumbnails && notification.content.thumbnails.length"
              class="mr-2"
              :src="image(notification.content)"
            />
            <strong>{{ notification.content.title }}</strong>
          </div>
        </b-dropdown-item>
      </action-dispatcher>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  computed: {
    ...mapState('user', ['notifications'])
  },
  methods: {
    image (item) {
      return get(get(item, 'thumbnails', []).find(item => item.device === 'web'), 'small', '')
    },
    clicked ({ content }) {
      this.$router.push({
        name: 'content',
        params: {
          id: content.id,
          slug: content.slug
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.template-notifications {
  .icon-notification {
    font-size: 32px;
    color: white;
  }

  img {
    width: 87px;
    height: 48px;
    object-fit: cover;
  }

  strong {
    font-size: 14px;
    line-height: 17px;
    white-space: normal;
    font-weight: bold;
  }

  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .dropdown-item {
    padding: 8px 16px;
  }

  ::v-deep .dropdown-menu {
    background: #212631;
    border-radius: 8px;
    width: 300px;
    max-width: 300px;

    li:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    a {
      color: white;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }

    hr {
      border-top-color: rgba(255, 255, 255, 0.05);
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #212631;
      top: -8px;
      right: 20px;
    }
  }
}
</style>
