export default {
  setName (state, name) {
    state.name = name
  },
  setDescription (state, description) {
    state.description = description
  },
  setPrice (state, price) {
    state.price = price
  },
  setDiscount (state, discount) {
    state.discount = discount
  },
  setCoupon (state, coupon) {
    state.coupon = coupon
  },
  setItems (state, items) {
    state.items = items
  },
  setTrialPeriodDays (state, trialPeriodDays) {
    state.trialPeriodDays = trialPeriodDays
  },
  setPaymentMethods (state, paymentMethods) {
    state.paymentMethods = paymentMethods
  }
}
