<template>
  <header class="page-header mb-4 pt-5" :style="headerBg" :class="{ lighter }">
    <div class="content container py-3 py-lg-5 pr-4">
      <div class="row">
        <div class="col-xl-5 col-lg-6 slot">
          <slot />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    lighter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headerBg () {
      return `background-image: url(${this.image})`
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  min-height: 650px;
  background-size: cover;
  position: relative;
  background-position-x: center;
  background-position-y: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    height: 500px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    right: 0;

    @media screen and (max-width: 767px) {
      background-image:
        linear-gradient(to top, #000215, rgba(0, 2, 21, 0.5) 50%),
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
    }
    @media screen and (min-width: 768px) {
      background-image:
        linear-gradient(to top, #000215, rgba(0, 2, 21, 0) 50%),
        linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
    }
  }

  &.lighter {
    &::before {
      content: '';

      @media screen and (max-width: 767px) {
        background-image:
          linear-gradient(to top, #000215, rgba(0, 2, 21, 0.3) 50%),
          linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3) 50%);
      }
      @media screen and (min-width: 768px) {
        background-image:
          linear-gradient(to top, #000215, rgba(0, 2, 21, 0) 50%),
          linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 50%);
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 22px;
    }

    h5 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }
}
</style>
