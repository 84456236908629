export default {
  cancel () {
    return new Promise((resolve, reject) => {
      window.axios
        .get('/subscriptions/cancel')
        .then(resolve)
        .catch(reject)
    })
  }
}
