import Vue from 'vue'
import App from './App.vue'

import BootstrapVue from 'bootstrap-vue'
import VueTheMask from 'vue-the-mask'
import VueTelInput from 'vue-tel-input'
import shareIt from 'vue-share-it'

import router from './router'
import store from './store'
import './assets/scss/app.scss'
import './assets/icomoon/style.css'
import './components'
import './plugins'
import { init } from './setup/api'

Vue.use(VueTheMask)
Vue.use(VueTelInput)
Vue.use(BootstrapVue)
Vue.use(shareIt)
init()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
