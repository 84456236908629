import store from '../store'
import router from '../router'
import { dayDiff } from '../functions/helpers'
import get from 'lodash/get'

export default {
  install (Vue) {
    Vue.prototype.$user = new Vue({
      store,
      router,
      computed: {
        user () {
          return this.$store.state.user.user
        },
        subscription () {
          return get(this.$store.state.user, 'user.subscription', {})
        },
        isInactive () {
          return (
            this.user && (!this.service.is_active || this.daysToExpire < 0)
          )
        },
        daysToExpire () {
          if (!this.user || !this.service) return ''
          return dayDiff(this.service.expired_at)
        }
      },
      methods: {
        logout () {
          this.$store.dispatch('logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        }
      }
    })
  }
}
