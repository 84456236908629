export default {
  setHighlights (state, highlights) {
    state.highlights = highlights
  },
  setMainHighlight (state, mainHighlight) {
    state.mainHighlight = mainHighlight
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setCategory (state, category) {
    state.category = category
  },
  setAlerts (state, alerts) {
    state.alerts = alerts
  }
}
