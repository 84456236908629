<template>
  <div class="view-home">
    <div v-if="!headerContent" class="header-spacing"></div>

    <!-- getting season in base of finished or not -->
    <action-dispatcher v-if="headerSeason" action="seasons/get" :parameters="headerSeason.id" loading-class="p-5 m-5">
      <page-header v-if="headerContent" :image="headerImage" lighter>
        <h5 v-if="headerContent.season">{{ headerContent.season_tmp.name }}</h5>
        <h6>
          <span v-if="headerContent.season" class="mr-2">Temporada {{ headerContent.season.number }}</span>
          <span v-if="headerContent.episode">Episódio {{ headerContent.episode }}</span>
        </h6>
        <router-link :to="{
          name: 'content',
          params: {
            id: headerContent.id,
            slug: headerContent.slug
          }
        }" class="text-light text-decoration-none">
          <h1>{{ headerContent.title }}</h1>
        </router-link>
        <p>
          <span>Categoria: <strong>{{ headerCategory }}</strong></span>
        </p>

        <div class="header-buttons d-flex mb-3">
          <watch-button class="mr-2" :content="headerContent" />
          <div class="mr-2">
            <big-list-button :content="headerContent" />
          </div>
        </div>
      </page-header>
    </action-dispatcher>

    <season-button :content="headerContent" />

    <div class="container-fluid pb-5">
      <div class="container">

        <template v-if="highlights && highlights.length">
          <h4 class="mb-0 mb-lg-1 mt-4 mt-lg-5">
            Destaques
          </h4>
          <series :seasons="highlights" :useLandscape="false" />
        </template>


        <action-dispatcher action="contents/getCategories">
          <template v-for="cat in categories">
            <h4 v-if="cat && cat.seasons.length" class="mb-0 mb-lg-1 mt-4 mt-lg-5">
              {{ cat.name }}
            </h4>
            <series :seasons="[...cat.seasons]" :categorySize="true" v-if="cat && cat.seasons.length" />
          </template>
        </action-dispatcher>

        <!-- <action-dispatcher v-show="continuations.length" action="contents/getContinuations">
          <carousel-wrapper :items="continuationList" title="Continuar assistindo" />
        </action-dispatcher> -->

        <h4 class="mb-0 mb-lg-1 mt-4 mt-lg-5">
          Meus cursos
        </h4>
        <series :seasons="orderedSeasons" :useLandscape="false" />

        <action-dispatcher action="homepage/getAlerts">
          <h4 v-if="alert" class="mb-0 mb-lg-1 mt-4 mt-lg-5">
            Alertas Importantes
          </h4>
          <div v-if="alert" class="row align-items-center pt-5">
            <div v-if="alert.picture" class="col-lg py-3">
              <img :src="alert.picture" class="img-fluid" />
            </div>
            <div class="col-lg py-3">
              <h3>{{ alert.title }}</h3>
              <div v-html="alert.description" />
            </div>
          </div>
        </action-dispatcher>

        <!-- <action-dispatcher action="user/getMyList">
          <carousel-wrapper :items="list" title="Minha lista" />
        </action-dispatcher>

        <carousel-wrapper
          v-for="(season, index) in seasons"
          :key="index"
          :items="season.content"
          :title="`Série: ${season.name}`"
        /> -->

        <!-- <super-recommended v-if="superRecommendation" class="pt-5" :item="superRecommendation" />

        <action-dispatcher action="contents/getLaunches">
          <carousel-wrapper :items="launches" title="Lançamentos" />
        </action-dispatcher>

        <action-dispatcher action="contents/getRecommendations">
          <carousel-wrapper :items="recommendations" title="Recomendados para você" />
        </action-dispatcher> -->
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import CarouselWrapper from '@/components/CarouselWrapper'
// import SuperRecommended from '@/components/home/SuperRecommended'
import SeasonButton from '@/components/SeasonButton'
import Series from '@/components/Series'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  name: 'home',
  components: {
    PageHeader,
    CarouselWrapper,
    // SuperRecommended,
    SeasonButton,
    Series
  },
  computed: {
    ...mapState('homepage', ['alerts']),
    ...mapState('seasons', ['seasons', 'season']),
    ...mapState('contents', ['launches', 'recommendations', 'continuations', 'categories', 'categoryContents']),
    ...mapState('user', ['myList']),
    continuationList() {
      return (this.continuations || []).splice(0, 5)
    },
    alert() {
      return get(this.alerts, '[0]')
    },
    list() {
      return this.myList.map(item => item.content)
    },
    orderedSeasons() {
      if (get(this.$user, 'user.teams')) {
        const teamsIds = this.$user.user.teams.map(h => h.seasontmp.id)
        const nonTeams = this.seasons.filter(season => !teamsIds.includes(season.id))
        const teams = this.$user.user.teams
          .map(item => this.seasons.find(season => season.id === item.seasontmp.id))
          .filter(item => item)
        return [...teams, ...nonTeams]
      }
      return this.seasons
    },
    highlights() {
      return (get(this.$user, 'user.teams') || [])
        .filter(item => item.is_highlights)
        .map(item => this.seasons.find(season => season.id === item.seasontmp.id))
        .filter(item => item)
    },
    highlight() {
      const highlight = this.seasons.filter(season => season.is_highlight)
      return [...highlight]
    },
    headerSeason() {
      const seasons = [
        ...this.highlight || [],
        ...this.highlights || [],
        ...this.orderedSeasons || []
      ]
      for (const i in seasons) {
        if (!seasons[i].finished) {
          return seasons[i]
        }
      }
      return get(seasons, '[0]', null)
    },
    headerContent() {
      const contents = get(this.season, 'content', [])
      let content
      for (const i in contents) {
        content = contents[i]
        if (
          !content.continue_watch.finished &&
          content.continue_watch.duration / (content.video.duration + 1) < 0.9
        ) {
          return content
        }
      }
      return content
    },
    headerImage() {
      return get(get(this.headerContent, 'thumbnails', []).find(item => item.device === 'web'), 'file', '')
    },
    headerCategory() {
      return get(this.headerContent, 'categories[0].name', '')
    },
    headerTags() {
      return get(this.headerContent, 'tags', []).map(item => `#${item.name}`).join(' ')
    },
    superRecommendation() {
      return get(this.recommendations, '[0]', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.view-home {
  .header-buttons {
    ::v-deep .like-button {
      font-size: 28px !important;
    }
  }

  h4 {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    .icon-right {
      font-size: 28px;
      display: inline-block;
      transform: translateY(5px);
    }
  }
}
</style>
