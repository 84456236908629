<template>
  <button v-if="active"
    class="pop-up-animation like-button"
    @click.stop="unlike"
    key="icon-heart-filled"
  >
    <i class="icon-heart-filled"></i>
  </button>
  <button v-else
    class="pop-up-animation like-button"
    @click.stop="like"
    key="icon-heart"
  >
    <i class="icon-heart"></i>
  </button>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    value: {
      type: [Number, Boolean],
      default: 0
    }
  },
  data () {
    return {
      active: !!this.value
    }
  },
  methods: {
    like () {
      this.active = true
      this.$store.dispatch('contents/like', { id: this.id, score: 1 })
    },
    unlike () {
      this.active = false
      this.$store.dispatch('contents/like', { id: this.id, score: 0 })
    }
  }
}
</script>

<style lang="scss" scoped>
.like-button {
  background: transparent;
  border: none;
  color: white;

  &:focus {
    outline: none;
  }
}
</style>
