<template>
  <button
    class="serie-button img-fluid" :style="`background-image: url(${backgroundImage(season)})`"
    :class="{ hover, large, categorySize }"
    @click.stop="open"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <div class="content">
      <h5>
        {{ season.name }}<br/>
        <small>
          {{ season.count_season }} Temporadas
          {{ season.count_episodes }} Episódios
        </small>
      </h5>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    useLandscape: {
      type: Boolean,
      default: true,
    },
    categorySize: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      hover: false,
      debounced: null
    }
  },
  methods: {
    mouseenter () {
      if (window.innerWidth > 991) {
        this.debounced = setTimeout(() => {
          this.hover = true
          this.$emit('mouseenter')
        }, 200)
      }
    },
    mouseleave () {
      clearTimeout(this.debounced)
      this.hover = false
      this.$emit('mouseleave')
    },
    open () {
      this.$router.push({
        name: 'season',
        params: {
          id: this.season.id,
          slug: this.season.slug || this.season.name
        }
      })
    },
    backgroundImage(season) {
      if (this.useLandscape) {
        return season.landscape;
      }
      return season.file;
    }
  }
}
</script>

<style lang="scss" scoped>
.serie-button {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 154%;
  border: none;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  transition: 0.3s all;

  .content {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    opacity: 0;
    transition: 0.3s all;
  }

  &.categorySize {
    padding-bottom: 60% !important;
  }

  &.large {
    width: 85% !important;
    margin-left:60px;
    padding-bottom: 30% !important;
    &.hover {
      transform: scale(1);
      z-index: 1;

      .progress-bar-wrapper {
        opacity: 0;
        background: transparent;
      }

      .content {
        background: rgba(0, 0, 0, 0.8);
        opacity: 1;
        color: white;
        padding: 12px;
      }
    }
  }
  
  &.hover {
    transform: scale(1.25);
    z-index: 1;

    .progress-bar-wrapper {
      opacity: 0;
      background: transparent;
    }

    .content {
      background: rgba(0, 0, 0, 0.8);
      opacity: 1;
      color: white;
      padding: 12px;
    }
  }

  &::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    padding-bottom: 100%;
  }

  h5 {
    position: absolute;
    font-weight: bold;
    line-height: 110%;
    text-align: left;
    color: #FFFFFF;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
  }
}
</style>
