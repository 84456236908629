import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions'
import state from './state'
import mutations from './mutations'

import user from './user'
import plans from './plans'
import contents from './contents'
import comments from './comments'
import subscriptions from './subscriptions'
import seasons from './seasons'
import homepage from './homepage'

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    user,
    plans,
    contents,
    comments,
    subscriptions,
    seasons,
    homepage
  }
})
