<template>
  <div class="carousel-wrapper">
    <h4 v-if="title.length" class="mb-0 mb-lg-1 mt-4 mt-lg-5">
      {{ title }}

      <i class="icon-right"></i>
    </h4>

    <div class="py-5 text-center text-secondary small" v-if="items.length === 0">
      Nenhum item para exibir
    </div>

    <carousel
      v-else
      :per-page-custom="[[768, 3], [992, 4], [1400, 5]]"
      :navigation-enabled="navigationEnabled"
      :pagination-enabled="false"
      navigation-next-label="<i class='icon-right'></i>"
      navigation-prev-label="<i class='icon-left'></i>"
    >
      <slide
        v-for="item in items"
        :key="item.id"
        class="px-1"
      >
        <carousel-media
          :item="item"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import CarouselMedia from './CarouselMedia'

export default {
  components: {
    CarouselMedia
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      navigationEnabled: true
    }
  },
  methods: {
    mouseenter () {
      this.navigationEnabled = false
    },
    mouseleave () {
      this.navigationEnabled = true
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-wrapper {
  h4 {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    .icon-right {
      font-size: 28px;
      display: inline-block;
      transform: translateY(5px);
    }
  }
}
</style>
